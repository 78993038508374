<template>
    <div class="outside">
        <rxNavBar title="租约变更申请" androidOrIOSFlag="true"></rxNavBar>
        <div class="head">
            <!-- 搜索栏-->
            <div class="search">
                <div class="search-icon"></div>
                <input type="text" placeholder="合同编号/房源地址/租客姓名、手机号" v-model="searchName" @keyup.enter="searchSubmit">
            </div>
            <div class="types">
                <!-- 点击显示和隐藏下拉菜单 -->
                <span class="type" @click="isShow = !isShow" id="downList">{{typeList[number]  ? typeList[number].dictionaryTitle : '未审批'}}</span>
                <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
            </div>
        </div>
        <div class="blank-weight"></div>
        <div class="reviews">
            <van-pull-refresh v-model="refreshing"  @refresh="onRefresh" >
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="noContent?'':'已经到底了'"
                        @load="this.onLoad"
                >
                    <!--变更申请列表-->
                    <div class="review" v-for="(item,index) in userLeaseChangeList" :key="index">
                        <div :class="item.approvalStatus=='0'?'top-title-orange':item.approvalStatus=='1'?'top-title-green':'top-title-red'">
                            {{item.approvalStatusStr}}</div>
                        <div class="position">
                            <span class="position-span-left">{{item.contractHistoryHistoryType}}</span>
                            <span class="position-span-right">·{{item.roomDetailedAddress}}</span>
                        </div>
                        <div class="details">
                            <div class="info">
                                <div class="order">{{item.contractType=='租客'?'租客姓名':'业主姓名'}}</div>
                                <div class="order-name">{{item.contractType=='租客'?item.renterName:item.ownerName}}</div>
                            </div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="order">违约金</div>
                                <div class="order-name">{{item.falsify ? item.falsify+'元' : '暂无'}}</div>
                            </div>
                            <div class="line"></div>
                            <div class="info">
                                <div class="order">退房时间</div>
                                <div class="order-name">{{item.estimatedDepartureTime ? item.estimatedDepartureTime : '暂无'}}</div>
                            </div>
                        </div>
                        <div class="reject" v-if="item.approvalStatus=='2'">
                            <div class="reject-num"><span>驳回次数：</span><span class="span-num">{{item.refuseCount}}</span></div>
                            <div class="reject-reason"><span>上次驳回原因：</span>{{item.refuseReason}}</div>
                        </div>
                        <div class="bottom">
                            <div class="time">{{item.approvalStatus!='2'?'申请时间':'驳回时间'}}：{{item.approvalStatus!='2'?item.applicationTime:item.approvalTime}}</div>
                            <div @click="pageJump(item)" :class="item.approvalStatus=='0'?'check':'look'" v-if="checkAuthList(authButtonsList,'apporveFinish')">
                                {{item.approvalStatus=='0'?'审核':'查看'}}
                            </div>
                            <div @click="cancel(item)" class="check" v-if="checkAuthList(authButtonsList,'cancel')">
                                取消
                            </div>                            
                        </div>
                    </div>
                    <common-empty v-if="noContent"></common-empty>
                </van-list>
            </van-pull-refresh>
        </div>
      <van-popup v-model="isShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="typeList"
            @cancel="isShow = false"
            :default-index="number"
            value-key="dictionaryTitle"
            @confirm="changeType"
        />
      </van-popup>
    </div>
</template>

<script>
    import { NavBar,Search,List,Picker,PullRefresh,Dialog,
      Popup, } from 'vant';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {findCancelContractApprovalList, queryBaseData,getStaffFuntionModelList} from "../../../getData/getData";
    import {getStaffId, responseUtil,checkAuthList} from "../../../libs/rongxunUtil";
    export default {
        name: "applyForChangeLease",
        data(){
            return{
                noContent: false,
                loading: false,
                finished: false,
                refreshing:false,
                page:1,
                number:1,//记录当前选择的是哪一种类型
                type:'未审批',
                typeList:[{dictionaryValue: '',dictionaryTitle: '全部类型'}],
                isShow:false,
                //租约变更列表
                userLeaseChangeList:[],
                searchName:'',//搜索关键字
                approvalStatus:'0',//0 待审批 1 已完成 2 审批拒绝
                rejectReason:'',
                refuseReasonList:[],
                authButtonsList:[]//权限列表
            }
        },
        components:{
            [NavBar.name]:NavBar,
            [Search.name]:Search,
            [List.name]:List,
            rxNavBar,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [PullRefresh.name]:PullRefresh,
            [Dialog.name]: Dialog,
        },
        created() {
            this.getStaffFuntionModelList()
            this.initApprovalStatusMap()
            // 记录用户的下拉选择，用于查看详情之后返回的初始化
            let currentLabel = JSON.parse(localStorage.getItem('currentLabel')); //先读取local里存储的历史记录
            if(currentLabel != null){
                if(currentLabel != '' ){
                    this.number = Number(currentLabel)
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == null){
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },
        methods:{
            cancel(item){
                console.log(item)
                Dialog.confirm({
                    title: '友情提示',
                    message: '确定取消吗？',
                }).then(() => {
                    let that = this
                    let queryData = {};
                    queryData.contractHistoryId = item.id;
                    queryData.status = that.number;
                    queryData.user_id = getStaffId()
                    queryData.contractHistoryHistoryType = item.contractHistoryHistoryType
                    queryData.contractType = item.contractType
                    console.log(queryData)
                    compulsoryDissolution(queryData).then(response => {
                      responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        that.onLoad()
                      })
                    })
                })
                .catch(() => {
                })
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.userLeaseChangeList = []
                this.page = 1
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.refreshing = false;
                this.onLoad();
                responseUtil.alertMsg(this,"刷新成功")
            },

            //获取权限方法
            getStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'relieveContractUp'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                        console.log(that.authButtonsList)
                    })
                })
            },
            checkAuthList,


            //页面跳转
            pageJump(item) {
                if(item.approvalStatus=='0'){
                    this.$router.push({
                        name: 'ownerQuit',
                        query: {
                            contractId:item.contractId,
                            ownerContractId:item.ownerContractId,
                            ch_id:item.ch_id,
                            id:item.id,//id 为审批id
                            lastRefuseCount:item.refuseCount,
                            lastRefuseReason:item.refuseReason || '',
                            type:item.contractType=='业主'?'2':'1',//1代表租客2代表业主
                            historyType:item.contractHistoryHistoryType,
                            contractTypeVal:item.contractStatusStr,
                            contractStatus:item.contractStatus,
                            mode:'1',//工作台状态
                            approvalFinishId:item.approvalFinishId
                        }
                    })
                }
                if(item.approvalStatus!='0'){
                    this.$router.push({
                        name: 'ownerQuitDetails',
                        query: {
                            contractId:item.contractId,
                            ch_id:item.ch_id,
                            id:item.id,//id 为审批id
                            lastRefuseCount:item.refuseCount,
                            lastRefuseReason:item.refuseReason || '',
                            type:item.contractType=='业主'?'2':'1',//1代表租客2代表业主
                            historyType:item.contractHistoryHistoryType,
                            contractTypeVal:item.contractStatusStr,
                            contractStatus:item.contractStatus,
                            mode:'1',//工作台状态
                            approvalFinishId:item.approvalFinishId
                        }
                    })
                }
            },
            searchSubmit(){
                this.changeType('',this.number)
            },
            showOrHidden: function(){
                this.isShow = !this.isShow;
            },
            changeType:function(value,index){
                //将选择的类型放入本地内存中，
                if(index == ''){
                    index = 0;
                }
                localStorage.setItem('currentLabel',JSON.stringify(index))
                this.page=1
                this.userLeaseChangeList=[]
                this.loading = false
                this.finished = false
                this.isShow=false
                this.number=index
                this.type=this.typeList[index]
                this.loading = true
                this.noContent = false
                this.onLoad()
            },
            onLoad:function(){
                this.initChangeList()
            },

            //页面初始化后台数据，默认加载全部租约变更信息
            initChangeList:function(){
                let that = this
                let initData={}
                //initData.type = '6'//审核类型6代表解除合同
                initData.staff_id = getStaffId()
                initData.currentPage = that.page+''
                initData.numberPage = "3"
                initData.searchName = that.searchName
                initData.approvalStatus = that.number>0 ? that.number-1 : ''
                findCancelContractApprovalList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        var count = response.data.data.pageCount
                        let list = response.data.data.data
                        console.log(list)
                        if (list){
                            for(let i=0;i<list.length;i++){
                                that.userLeaseChangeList.push(list[i])
                            }
                        }
                        that.loading = false;
                        if(that.refreshing){
                            that.refreshing = false
                        }
                        //判断是否加载完毕
                        if (that.userLeaseChangeList.length >=count) {
                            that.finished = true;
                        }
                        if(1==that.page&&(!list || list.length==0)){
                            that.noContent = true
                        }else{
                            that.noContent = false
                        }
                        that.page++;
                    })
                })
            },
            //初始化审批状态字典项
            initApprovalStatusMap(){
                let that = this
                let initData={}
                initData.fdName = ["APPROVESTATUSMAP"]
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        //console.log(response.data.data.APPROVESTATUSMAP)
                        let list = response.data.data.APPROVESTATUSMAP
                        if(list){
                            for(let i=0;i<list.length;i++){
                                that.typeList.push(list[i])
                            }
                        }
                        console.log(that.typeList)
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .outside{
        overflow: hidden;
        width: 100%;
    }
    .navbar{
        z-index: 10;
        background-color: #f8f8f8;
    }
    .head{
        width: 100%;
        position: fixed;
        z-index: 8;
        overflow: hidden;
        background: #f8f8f8;
        top: 52px;
    }
    .search{
        margin: 20px 20px 0 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }
    .search-icon{
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    input{
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }
    input::-webkit-input-placeholder{
        color: #d9d9d9;
    }
    .types{
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
    }
    .type{
        font-size: 14px;
        color: #fe5e3a;
        margin-left: 15px;
    }
    .small-icon{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .small-icon-rotate{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-bottom: 5px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform:rotate(180deg);
    }
    .selectList{
        width: 346px;
        /*height: 107px;*/
        height: auto;
        margin-left: 14px;
        background-color: #f4f4f4;
        border-radius: 8px;
    }
    .select{
        width: 100%;
        height: 53px;
        line-height: 53px;
        font-size: 14px;
        text-align: center;
        color: #999999;
    }
    .selectActive{
        width: 100%;
        height: 53px;
        line-height: 53px;
        font-size: 14px;
        text-align: center;
        color: #fe5e3a;
    }
    .h-line{
        height: 1px;
        margin: 0 5px 0 10px;
        background-color: white;
    }
    .blank-weight{
        height: 100px;
    }
    .reviews{
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .review{
        margin: 0 15px 15px 15px;
        height: auto;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }
    .top-title-orange{
        margin: 0;
        width: fit-content;
        padding: 0 5px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
    }
    .top-title-green{
        margin: 0;
        width: fit-content;
        padding: 0 5px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
    }
    .top-title-red{
        margin: 0;
        width: fit-content;
        padding: 0 5px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#fc401b,#e02321);
        color: white;
    }
    .position{
        margin: 15px 15px 0 15px;
        height: auto;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        /*overflow: hidden;!*超出部分隐藏*!*/
        /*white-space: nowrap;!*不换行*!*/
        /*text-overflow:ellipsis;!*超出部分文字以...显示*!*/
    }
    .position-span-left{
        line-height: 20px;
        color: #fe5e3a;
    }
    .position-span-right{
        line-height: 20px;
        color: black;
    }
    .details{
        margin-top: 10px;
        width: 100%;
        height: auto;
        border-bottom: 1px solid #f4f4f4;
        display: flex;
    }
    .details .info{
        width: 114px;
        height: auto;
        text-align: center;
        margin-top: 10px;
    }
    .details .info .order{
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
    }
    .details .info .order-name{
        width: 100%;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #fe5e3a;
        margin-top: 10px;
        margin-bottom: 15px;
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow: hidden;
        font-weight: bold;
    }
    .details .line{
        width: 1px;
        height: 40px;
        background-color: #f4f4f4;
        margin-top: 18px;
    }
    .reject{
        width: 100%;
        height: auto;
        border-bottom: 1px solid #f4f4f4;
    }
    .reject-num{
        margin: 15px 15px 5px 15px;
        color: #999999;
        font-size: 14px;
    }
    .span-num{
        color:#fe5e3a;
    }
    .reject-reason{
        margin: 0 15px 15px 15px;
        color: #999999;
        font-size: 14px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .bottom{
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
    }
    .bottom .time{
        width: 180px;
        height: 20px;
        text-align: left;
        line-height: 20px;
        font-size: 14px;
        color: #999999;
        margin-left: 15px;
        white-space: nowrap;
    }
    .bottom .look{
        width: 90px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        margin-left: 42px;
        color: #fe5e3a;
        border-radius: 8px;
        border: 1px solid transparent;
        background-image: linear-gradient(white,white),linear-gradient(to right,#ffd59e,#ffa08a);
        background-clip: padding-box,border-box;
        background-origin: border-box;
    }
    .bottom .check{
        width: 90px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        color: white;
        margin-left: 42px;
        border-radius: 8px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
    }

</style>
